@import "../../assets/base-theme.scss";
@import "../Navigation/styles.scss";

$accent: #3A55D7;
$background: #F1DACF;
$body-color: #808080;
$body-width: 700px;

.home-page {
  .navigation {
    @extend .navigation-default-desktop;
    .link a {
      color: $body-color;
      text-decoration-color: $accent !important;
    }
  }
  &.page {
    background-color: $background;
    height: 100vh;
    padding-top: 200px;

    .body {

      width: 100%;
      max-width: calc(100vw - 380px);
      @media(max-width: $media-sm) {
        width: 100%;
        .title {
          font-size: 100px;
          position: relative;
          z-index: 900;
        }
        
      }
      @media(min-width: $media-sm - 1) {
        .title {
          font-size: 250px;
          line-height: 230px;
        }
      }

      .title {
        color: white;
        font-family: ltc-bodoni-175, serif;
        font-weight: $font-weight-serif-header;
        margin-bottom: -16px;
      }

      .message { 
        max-width: 700px;
        color: $body-color;
        font-family: $font-family-body-big;
        font-size: $font-size-body-big;
        font-weight: 300;
      }
    }

    .home-img {
      position: fixed;
      z-index: 899;
      right: 0;
      bottom: 0;
      // width: 800px;
      max-width: calc(100vw - 700px);
      height: calc(100vh - 64px);
      overflow: hidden;
      img {
        height: 100%;
      }
    }
  }
}
