.underline-fadein {
  position: relative;
  overflow: hidden;
}

.underline-fadein:after {
  content: "";
  background-color: #ff69b4;
  width: 100%;
  height: 1px;
  transition: opacity .3s, transform .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(-101%, 0, 0);
}

.underline-fadein:hover:after, .underline-fadein:focus:after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.navigation-default-desktop {
  position: absolute;
  top: 0;
  right: 8px;
}

.navigation-default-desktop .link {
  margin-left: 48px;
  display: inline-block;
}

.navigation-default-desktop .control-icons {
  display: none;
}

.navigation {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 32px;
  font-weight: 200;
}

.navigation .link a {
  text-decoration: none;
}

.navigation .link a:hover {
  text-decoration-thickness: 1px !important;
}

.underline-fadein {
  position: relative;
  overflow: hidden;
}

.underline-fadein:after {
  content: "";
  background-color: #ff69b4;
  width: 100%;
  height: 1px;
  transition: opacity .3s, transform .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(-101%, 0, 0);
}

.underline-fadein:hover:after, .underline-fadein:focus:after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.navigation-default-desktop, .home-page .navigation {
  position: absolute;
  top: 0;
  right: 8px;
}

.navigation-default-desktop .link, .home-page .navigation .link {
  margin-left: 48px;
  display: inline-block;
}

.navigation-default-desktop .control-icons, .home-page .navigation .control-icons {
  display: none;
}

.navigation {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 32px;
  font-weight: 200;
}

.navigation .link a {
  text-decoration: none;
}

.navigation .link a:hover {
  text-decoration-thickness: 1px !important;
}

.home-page .navigation .link a {
  color: gray;
  text-decoration-color: #3a55d7 !important;
}

.home-page.page {
  background-color: #f1dacf;
  height: 100vh;
  padding-top: 200px;
}

.home-page.page .body {
  width: 100%;
  max-width: calc(100vw - 380px);
}

@media (width <= 640px) {
  .home-page.page .body {
    width: 100%;
  }

  .home-page.page .body .title {
    z-index: 900;
    font-size: 100px;
    position: relative;
  }
}

@media (width >= 639px) {
  .home-page.page .body .title {
    font-size: 250px;
    line-height: 230px;
  }
}

.home-page.page .body .title {
  color: #fff;
  margin-bottom: -16px;
  font-family: ltc-bodoni-175, serif;
  font-weight: 400;
}

.home-page.page .body .message {
  color: gray;
  max-width: 700px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 32px;
  font-weight: 300;
}

.home-page.page .home-img {
  z-index: 899;
  max-width: calc(100vw - 700px);
  height: calc(100vh - 64px);
  position: fixed;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.home-page.page .home-img img {
  height: 100%;
}

.underline-fadein {
  position: relative;
  overflow: hidden;
}

.underline-fadein:after {
  content: "";
  background-color: #ff69b4;
  width: 100%;
  height: 1px;
  transition: opacity .3s, transform .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(-101%, 0, 0);
}

.underline-fadein:hover:after, .underline-fadein:focus:after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.navigation-default-desktop, .articles-page .navigation {
  position: absolute;
  top: 0;
  right: 8px;
}

.navigation-default-desktop .link, .articles-page .navigation .link {
  margin-left: 48px;
  display: inline-block;
}

.navigation-default-desktop .control-icons, .articles-page .navigation .control-icons {
  display: none;
}

.navigation {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 32px;
  font-weight: 200;
}

.navigation .link a {
  text-decoration: none;
}

.navigation .link a:hover {
  text-decoration-thickness: 1px !important;
}

.articles-page .navigation .link a {
  color: #000;
  text-decoration-color: #000 !important;
}

.articles-page .blog-home .page-description {
  margin-bottom: 24px;
  font-family: adobe-caslon-pro, serif;
  font-size: 22px;
}

.article-nav {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 32px;
  font-weight: 200;
}

.article-nav .link {
  text-decoration-color: #000 !important;
}

.article-nav .link a {
  font-family: ltc-bodoni-175, serif;
  text-decoration: none;
  text-decoration-color: #000 !important;
}

.article-nav .link a:hover {
  text-decoration-thickness: 1px !important;
}

.article {
  width: 100%;
  padding-left: 12.5%;
}

@media only screen and (width <= 1280px) {
  .article {
    padding-left: 5%;
  }
}

.article blockquote {
  font-style: italic;
}

.article h1 {
  width: 90%;
  font-family: ltc-bodoni-175, serif;
  font-size: 3rem;
  font-weight: 100;
}

.article h2 {
  width: 90%;
  font-family: ltc-bodoni-175, serif;
  font-size: 2rem;
  font-weight: 100;
}

.article h3 {
  width: 90%;
  font-family: ltc-bodoni-175, serif;
  font-size: 1.75rem;
  font-weight: 100;
}

.article h4 {
  width: 90%;
  font-family: ltc-bodoni-175, serif;
  font-size: 1.4rem;
  font-weight: 100;
}

.article img {
  width: 80%;
  max-width: 1024px;
}

.article ul {
  width: 55%;
}

@media only screen and (width <= 1280px) {
  .article ul {
    width: 90%;
  }
}

.article p {
  width: 55%;
  font-family: adobe-caslon-pro, serif;
  font-size: 1.25rem;
}

@media only screen and (width <= 1280px) {
  .article p {
    width: 90%;
  }
}

.article p code {
  font-size: 18px;
}

.article pre {
  width: 55%;
}

@media only screen and (width <= 1280px) {
  .article pre {
    width: 90%;
  }
}

.article code {
  color: #00008b;
  font-family: lft-etica-mono;
}

.article li {
  font-family: adobe-caslon-pro, serif;
  font-size: 1.4rem;
}

.underline-fadein {
  position: relative;
  overflow: hidden;
}

.underline-fadein:after {
  content: "";
  background-color: #ff69b4;
  width: 100%;
  height: 1px;
  transition: opacity .3s, transform .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(-101%, 0, 0);
}

.underline-fadein:hover:after, .underline-fadein:focus:after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.navigation-default-desktop {
  position: absolute;
  top: 0;
  right: 8px;
}

.navigation-default-desktop .link {
  margin-left: 48px;
  display: inline-block;
}

.navigation-default-desktop .control-icons {
  display: none;
}

.navigation {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 32px;
  font-weight: 200;
}

.navigation .link a {
  text-decoration: none;
}

.navigation .link a:hover {
  text-decoration-thickness: 1px !important;
}

.contact-page {
  color: #4f4f4f;
  background: #eefbf0;
  width: 100vw;
  height: 100vh;
}

@media (width <= 640px) {
  .contact-page .navigation {
    text-align: right;
    border-top: 1px solid #666;
    width: 100vw;
    height: 24px;
    padding-bottom: 24px;
    transition: height .2s;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .contact-page .navigation .link {
    float: right;
    clear: both;
    margin-bottom: 32px;
    margin-right: 22px;
    transition: top .2s;
    display: table;
    position: relative;
    top: 275px;
  }

  .contact-page .navigation .control-icons {
    cursor: pointer;
    margin-right: 12px;
    transition: transform .5s;
    position: absolute;
    bottom: -4px;
    right: 0;
    transform: rotate(540deg);
  }

  .contact-page .navigation.open {
    height: 215px;
  }

  .contact-page .navigation.open .link {
    top: 0;
  }

  .contact-page .navigation.open .control-icons {
    transform: rotate(180deg);
  }

  .contact-page .navigation .control-icons {
    color: #353535;
  }
}

@media (width >= 639px) {
  .contact-page .navigation {
    position: absolute;
    top: 0;
    right: 8px;
  }

  .contact-page .navigation .link {
    margin-left: 48px;
    display: inline-block;
  }

  .contact-page .navigation .control-icons {
    display: none;
  }
}

.contact-page .navigation .link a {
  color: #4f4f4f;
}

.contact-page > .content {
  margin-left: 8px;
  position: relative;
  top: 128px;
}

.contact-page > .content > .title {
  font-family: ltc-bodoni-175, serif;
  font-size: 128px;
  font-weight: 300;
}

.contact-page > .content .location {
  font-family: ltc-bodoni-175, serif;
  font-size: 20px;
  font-weight: 100;
  position: relative;
  top: -16px;
  right: -2px;
}

.contact-page > .content .links {
  grid-template-columns: auto auto auto auto;
  margin: 24px 0;
  display: grid;
}

.contact-page > .content .links > .link {
  display: ineline-block;
}

.contact-page > .content .links > .link > .label {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 16px;
  font-weight: 550;
}

.contact-page > .content .links > .link > .content {
  text-transform: lowercase;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 22px;
  font-weight: 200;
}

.contact-page > .content .links > .link > .content > a {
  text-decoration: none;
}

.underline-fadein {
  position: relative;
  overflow: hidden;
}

.underline-fadein:after {
  content: "";
  background-color: #ff69b4;
  width: 100%;
  height: 1px;
  transition: opacity .3s, transform .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(-101%, 0, 0);
}

.underline-fadein:hover:after, .underline-fadein:focus:after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.navigation-default-desktop, .cv-page .summary .navigation {
  position: absolute;
  top: 0;
  right: 8px;
}

.navigation-default-desktop .link, .cv-page .summary .navigation .link {
  margin-left: 48px;
  display: inline-block;
}

.navigation-default-desktop .control-icons, .cv-page .summary .navigation .control-icons {
  display: none;
}

.navigation {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 32px;
  font-weight: 200;
}

.navigation .link a {
  text-decoration: none;
}

.navigation .link a:hover {
  text-decoration-thickness: 1px !important;
}

.cv-page .section {
  margin-bottom: 48px;
}

.cv-page .section > .section-title {
  text-transform: lowercase;
  font-family: adobe-caslon-pro, serif;
  font-size: 99px;
}

.cv-page .summary {
  color: #fff;
  background: #6b95d0;
  padding: 32px 0;
}

.cv-page .summary .summary-content {
  grid-template-columns: auto auto;
  max-width: 1280px;
  display: grid;
}

.cv-page .summary .summary-content .face-picture > span img {
  height: 400px;
  margin-left: 64px;
}

.cv-page .summary .summary-content .face-picture > span .smile, .cv-page .summary .summary-content .face-picture > span:hover .normal {
  display: none;
}

.cv-page .summary .summary-content .face-picture > span:hover .smile {
  display: inline;
}

.cv-page .summary .navigation .underline-fadein:after {
  opacity: .7;
  background-color: #fff;
}

.cv-page .summary .title {
  text-transform: lowercase;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 120px;
}

.cv-page .summary .title .download-icon {
  cursor: pointer;
  margin-left: 20px;
}

.cv-page .experiences {
  color: #333;
  background: #fff9b3;
  margin-bottom: 18px;
  padding-bottom: 1px;
  position: relative;
}

@media (width <= 1200px) {
  .cv-page .experiences {
    padding-top: 100px;
  }
}

.cv-page .experiences > .section-title {
  position: absolute;
  top: -20px;
  right: 0;
}

.cv-page .experiences .experience {
  margin-bottom: 48px;
  position: relative;
}

.cv-page .experiences .experience .company {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 32px;
  font-weight: 600;
  display: inline-block;
}

.cv-page .experiences .experience .title, .cv-page .experiences .experience .date {
  text-transform: lowercase;
  margin-left: 2px;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 32px;
  font-weight: 300;
  display: inline-block;
}

.cv-page .experiences .experience .date-sep {
  margin: 0 8px;
  font-size: 38px;
  position: relative;
  top: 4px;
}

.cv-page .experiences .experience .responsibilities .responsibility {
  font-family: aktiv-grotesk, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.cv-page .experiences .experience .logo {
  display: inline-block;
  position: absolute;
  top: 8px;
}

.cv-page .experiences .experience .logo img {
  opacity: .3;
  width: 80px;
  position: relative;
  left: 30px;
}

.cv-page .skills {
  padding-right: 4px;
}

.cv-page .skills .skill {
  text-align: right;
  margin-bottom: 24px;
}

.cv-page .skills .skill .category {
  text-transform: lowercase;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 22px;
  font-weight: 300;
}

.cv-page .skills .skill .details {
  text-transform: lowercase;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.cv-page .education {
  color: #333;
  background-color: #b5fbbe;
  position: relative;
}

@media (width <= 1200px) {
  .cv-page .education {
    padding-top: 100px;
  }
}

.cv-page .education .details {
  text-align: right;
  position: absolute;
  top: 24px;
  right: 0;
}

.cv-page .education .details .school {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.cv-page .education .details .program {
  text-transform: lowercase;
  margin-left: 2px;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 32px;
  font-weight: 300;
  display: inline-block;
}

.underline-fadein {
  position: relative;
  overflow: hidden;
}

.underline-fadein:after {
  content: "";
  background-color: #ff69b4;
  width: 100%;
  height: 1px;
  transition: opacity .3s, transform .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(-101%, 0, 0);
}

.underline-fadein:hover:after, .underline-fadein:focus:after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.navigation-default-desktop, .links-page .navigation {
  position: absolute;
  top: 0;
  right: 8px;
}

.navigation-default-desktop .link, .links-page .navigation .link {
  margin-left: 48px;
  display: inline-block;
}

.navigation-default-desktop .control-icons, .links-page .navigation .control-icons {
  display: none;
}

.navigation {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 32px;
  font-weight: 200;
}

.navigation .link a {
  text-decoration: none;
}

.navigation .link a:hover {
  text-decoration-thickness: 1px !important;
}

.links-page {
  width: 100vw;
  height: 100vh;
}

.links-page .navigation .link a {
  color: #f36;
  background: #fffb01;
  padding: 0 8px;
}

.links-page .title {
  color: #f36;
  font-family: ltc-bodoni-175, serif;
  font-size: 195px;
  font-style: italic;
}

.links-page .links {
  background-color: #fffb01;
  width: 100vw;
  position: fixed;
  bottom: 0;
}

.links-page .links .father-link {
  width: 48%;
  display: inline-block;
  position: relative;
  bottom: -8px;
}

.links-page .links .father-link img {
  max-height: calc(100vh - 200px);
}

.links-page .links .terry-link {
  float: right;
  text-align: right;
  width: 48%;
  position: relative;
  bottom: -8px;
}

.links-page .links .terry-link img {
  max-height: calc(100vh - 200px);
}

.links-page .links .link {
  color: #f36;
  font-family: ltc-bodoni-175, serif;
  font-size: 80px;
  position: absolute;
  top: 50%;
  left: 40%;
}

body {
  margin: 0;
}

a, a:visited {
  color: inherit;
}

/*# sourceMappingURL=index.b4676327.css.map */
