@import "../../assets/base-theme.scss";

$open-transition: 200ms;

@mixin navigation-default-desktop {
  position: absolute;
  top: 0;
  right: 8px;

  .link {
    display: inline-block;
    margin-left: 48px
  }

  .control-icons {
    display: none;
  }
  
}

.navigation-default-desktop {
  @include navigation-default-desktop;
}

@mixin navigation-default-mobile {
  border-top: 1px solid black;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: 24px;
  text-align: right;
  width: 100vw;
  height: 24px;
  transition: height $open-transition;

  .link {
    display: table;
    float: right;
    clear: both;
    position: relative;
    
    margin-bottom: 32px;
    margin-right: 22px;
    top: 275px;
    transition: top $open-transition;
  }

  .control-icons {
    margin-right: 12px;
    position: absolute;
    bottom: -4px;
    right: 0;
    cursor: pointer;
    transform: rotate(540deg);
    transition: transform 0.5s;
  }

  &.open {
    height: 215px;

    .link {
      top: 0px;
    }

    .control-icons {
      transform: rotate(180deg);
    }
  }
}


.navigation {
  font-family: $font-family-serif-header;
  font-weight: 200;
  font-size: 32px;
  
  .link {
    a {
      text-decoration: none;
      &:hover {
        text-decoration-thickness: 1px !important;
      }
    }
  }
}