@import "../../assets/base-theme.scss";
@import "../Navigation/styles.scss";

$text-color: #4F4F4F;
$text-but-lighter: #666666;
$text-but-darker: #353535;

.contact-page {
  color: $text-color;
  background: #EEFBF0;
  height: 100vh;
  width: 100vw;

  .navigation {
    @media(max-width: $media-sm) {
      @include navigation-default-mobile;
      border-top: 1px solid $text-but-lighter;
      .control-icons { color: $text-but-darker; }
    }
    @media(min-width: $media-sm - 1) { @include navigation-default-desktop; }

    .link a {
      color: $text-color;
    }
  }

  > .content {
    position: relative;
    top: 128px;
    margin-left: 8px;

    > .title {
      font-family: ltc-bodoni-175, serif;
      font-size: 128px;
      // text-transform: lowercase;
      font-weight: 300;
    }

    .location {
      position: relative;
      top: -16px;
      right: -2px;
      font-family: ltc-bodoni-175, serif;
      font-weight: 100;
      font-size: 20px;
    }

    .links {
      display: grid;
      grid-template-columns: auto auto auto auto;
      margin: 24px 0px;
      > .link {
        display: ineline-block;

        > .label {
          font-family: $font-family-neue-haas;
          font-weight: 550;
          font-size: 16px;
        }
        > .content {
          font-family: $font-family-body;
          font-weight: 200;
          font-size: 22px;
          text-transform: lowercase;
          > a {
            text-decoration: none;
          }
        }
      }
    }
  }
}