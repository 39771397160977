@import "../../assets/base-theme.scss";
@import "../Navigation/styles.scss";

$body-color: black;
$accent: black;
$narrow-cutoff: 1280px;

.articles-page {
  .navigation {
    @extend .navigation-default-desktop;
    .link a {
      color: $body-color;
      text-decoration-color: $accent !important;
    }
  }

  .blog-home {

    .page-description {
      font-family: $font-family-calson;
      font-size: 22px;
      margin-bottom: 24px;
    }
  }
}

.article-nav {
  font-family: $font-family-serif-header;
  font-weight: 200;
  font-size: 32px;

  .link {
    text-decoration-color: $accent !important;
    a {
      font-family: ltc-bodoni-175, serif;
      text-decoration: none;
      text-decoration-color: black !important;
      &:hover {
        text-decoration-thickness: 1px !important;
      }
    }
  }
}

.articles-navigation {

}

.article {
  padding-left: 12.5%;
  @media only screen and (max-width: $narrow-cutoff) { padding-left: 5% }
  width: 100%;
  
  blockquote {
    font-style: italic;
  }

  h1 {
    font-family: ltc-bodoni-175, serif;
    font-size: 3rem;
    font-weight: 100;
    width: 90%;
  }
  
  h2 {
    font-family: ltc-bodoni-175, serif;
    font-size: 2rem;
    font-weight: 100;
    width: 90%;
  }

  h3 {
    font-family: ltc-bodoni-175, serif;
    font-size: 1.75rem;
    font-weight: 100;
    width: 90%;
  }

  h4 {
    font-family: ltc-bodoni-175, serif;
    font-size: 1.4rem;
    font-weight: 100;
    width: 90%;
  }

  img {
    max-width: 1024px;
    width: 80%;
  }

  ul {
    width: 55%;
    @media only screen and (max-width: $narrow-cutoff) { width: 90%; }
  }

  p {
    font-family: $font-family-calson;
    font-size: 1.25rem;
    width: 55%;
    @media only screen and (max-width: $narrow-cutoff) { width: 90%; }
    code {
      font-size: 18px
    }
  }

  pre {
    width: 55%;
    @media only screen and (max-width: $narrow-cutoff) { width: 90%; }
  }

  code {
    font-family: lft-etica-mono;
    color: darkblue;
  }

  li {
    font-family: $font-family-calson;
    font-size: 1.4rem;
  }
}