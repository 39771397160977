@import "../../assets/base-theme.scss";
@import "../Navigation/styles.scss";

$content-width-dexktop: 1280px;

$summary-background: #6B95D0;
$exp-background: #FFF9B3;
$section-gap: 48px;
$dark-font: #333333;

.cv-page {

  .section {
    margin-bottom: $section-gap;
    > .section-title {
      font-family: $font-family-calson;
      font-size: 99px;
      text-transform: lowercase;
    }
  }

  .summary {
    background: $summary-background;
    
    color: white;
    padding: 32px 0px;

    .summary-content {
      display: grid;
      grid-template-columns: auto auto;
      max-width: $content-width-dexktop;

      .face-picture > span {
        img {
          margin-left: 64px;
          height: 400px;
        }
        .smile { display: none;}
        &:hover {
          .normal { display: none;}
          .smile { display: inline; }
        }

      }
    }

    .navigation {
      @extend .navigation-default-desktop;
      .underline-fadein:after {
        background-color: white;
        opacity: 0.7;
      }
    }

    .title {
      font-family: $font-family-serif-header;
      font-size: 120px;
      text-transform: lowercase;

      .download-icon {
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }

  .experiences {
    color: $dark-font;
    background: $exp-background;
    padding-bottom: 1px;
    margin-bottom: 18px;
    position: relative;

    @media(max-width: 1200px) {
      padding-top: 100px;
    }

    > .section-title {
      position: absolute;
      right: 0;
      top: -20px;
    }

    .experience {
      margin-bottom: 48px;
      position: relative;

      .company {
        display: inline-block;
        font-family: $font-family-serif-header;
        font-weight: 600;
        font-size: 32px;
      }

      .title {
        display: inline-block;
        font-family: $font-family-body;
        font-weight: 300;
        font-size: 32px;
        margin-left: 2px;
        text-transform: lowercase;
      }

      .date {
        display: inline-block;
        font-family: $font-family-body;
        font-weight: 300;
        font-size: 32px;
        margin-left: 2px;
        text-transform: lowercase;
      }

      .date-sep {
        font-size: 38px;
        margin: 0px 8px;
        position: relative;
        top: 4px;
      }

      .responsibilities {
        .responsibility {
          font-family: $font-family-body;
          font-weight: 400;
          font-size: 18px;
        }
      }

      .logo {
        display: inline-block;
        // float: right;
        position: absolute;
        top: 8px;
        // right: 10px;

        img {
          position: relative;
          left: 30px;
          width: 80px;
          opacity: 0.3;
        }
      }
    }
  }

  .skills {
    
    padding-right: 4px;

    .skill {
      margin-bottom: 24px;
      text-align: right;

      .category {
        font-family: $font-family-body;
        font-weight: $font-weight-body-light;
        font-size: 22px;
        text-transform: lowercase;
      }

      .details {
        font-family: $font-family-body;
        font-weight: $font-weight-body-light;
        font-size: 16px;
        text-transform: lowercase;
      }
    }
  }

  .education {
    background-color: #B5FBBE;
    color: $dark-font;
    position: relative;

    @media(max-width: 1200px) {
      padding-top: 100px;
    }

    .details {
      position: absolute;
      right: 0;
      top: 24px;
      text-align: right;

      .school {
        font-family: $font-family-serif-header;
        font-weight: 600;
        font-size: 32px;
      }

      .program {
        display: inline-block;
        display: inline-block;
        font-family: $font-family-body;
        font-weight: 300;
        font-size: 32px;
        margin-left: 2px;
        text-transform: lowercase;
      }
    }
  }
}