
// FONT FAMILIES

$font-family-neue-haas: neue-haas-grotesk-display, sans-serif;
$font-family-serif-header: neue-haas-grotesk-display, sans-serif;
$font-family-body-big: $font-family-serif-header;

$font-family-body: aktiv-grotesk, sans-serif;

$font-family-calson: adobe-caslon-pro, serif;

// FONT WEIGHTS

$font-weight-serif-header: 400;
$font-weight-body-regular: 400;
$font-weight-body-light: 300;

// FONT SIZES

$font-size-header-big: 120px;
$font-size-body-big: 32px;


// media queries for page size
$media-sm: 640px;


// underline fade css
.underline-fadein {
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: hotpink;
    transition: opacity 300ms, transform 300ms;
    transform: translate3d(-101%, 0, 0);
  }
  &:hover::after,&:focus::after {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

